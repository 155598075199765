import React from 'react';
import { Settings, User, LogOut, Grid, Key, Shield, Crown, KeySquare } from 'lucide-react';
import { useNavigate, NavLink } from 'react-router-dom';
import { ThemeToggle } from './ThemeToggle';
import { DnaLogo } from './DnaLogo';
import { NotificationBell } from './NotificationBell';
import useAuthStore from '../services/authStore';
import useLicenseStore from '../services/licenseStore';

const Navbar: React.FC = () => {
  const navigate = useNavigate();
  const { user, logout } = useAuthStore();
  const { validateLicense } = useLicenseStore();
  const [showUserMenu, setShowUserMenu] = React.useState(false);

  const isOwner = user?.email?.toLowerCase() === 'jessiecrawford1985@gmail.com';
  const hasValidLicense = validateLicense();

  const handleLogout = () => {
    if (logout) {
      logout();
      navigate('/login');
    }
  };

  const navItems = [
    {
      to: "/",
      icon: <Grid className="h-4 w-4" />,
      label: "Dashboard"
    },
    {
      to: "/vault",
      icon: <Key className="h-4 w-4" />,
      label: "Vault"
    },
    {
      to: "/data-breach",
      icon: <Shield className="h-4 w-4" />,
      label: "Data Breach",
      pro: !hasValidLicense && !isOwner
    },
    ...(isOwner ? [{
      to: "/license-keys",
      icon: <KeySquare className="h-4 w-4" />,
      label: "License Keys"
    }] : [])
  ];

  return (
    <nav className="bg-gray-900 px-4 py-2 ios-safe-area">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <DnaLogo size="sm" />
          
          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-2 bg-gray-800 rounded-lg p-1">
            {navItems.map((item) => (
              <NavLink
                key={item.to}
                to={item.to}
                className={({ isActive }) =>
                  `flex items-center gap-2 px-3 py-2 rounded-lg ${
                    isActive
                      ? 'bg-blue-600 text-white'
                      : 'text-gray-300 hover:bg-gray-700'
                  }`
                }
              >
                {item.icon}
                <span>{item.label}</span>
                {item.pro && (
                  <span className="ml-1 px-1.5 py-0.5 text-xs bg-purple-500 text-white rounded-full flex items-center gap-1">
                    <Crown className="h-3 w-3" />
                    PRO
                  </span>
                )}
              </NavLink>
            ))}
          </div>
        </div>

        <div className="flex items-center gap-4">
          <NotificationBell />
          <ThemeToggle />

          <button 
            onClick={() => navigate('/settings')}
            className="p-1.5 text-gray-400 hover:text-gray-300 rounded-lg hover:bg-gray-800"
          >
            <Settings className="h-5 w-5" />
          </button>

          <div className="relative">
            <button 
              onClick={() => setShowUserMenu(!showUserMenu)}
              className="flex items-center gap-2 p-1.5 text-gray-300 hover:text-white rounded-lg hover:bg-gray-800"
            >
              <div className="h-6 w-6 rounded-full bg-blue-900 flex items-center justify-center">
                <User className="h-4 w-4 text-blue-300" />
              </div>
              <span className="hidden sm:block text-sm">
                {user?.name || 'User'}
              </span>
            </button>
            
            {showUserMenu && (
              <div className="absolute right-0 mt-2 w-48 bg-white dark:bg-gray-800 rounded-lg shadow-lg border border-gray-200 dark:border-gray-700 z-50">
                <div className="py-1">
                  <button
                    onClick={handleLogout}
                    className="w-full flex items-center gap-2 px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700"
                  >
                    <LogOut className="h-4 w-4" />
                    Sign Out
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Mobile Navigation */}
      <div className="md:hidden fixed bottom-0 left-0 right-0 bg-gray-900 border-t border-gray-800 ios-safe-area">
        <div className="flex justify-around py-2">
          {navItems.map((item) => (
            <NavLink
              key={item.to}
              to={item.to}
              className={({ isActive }) =>
                `flex flex-col items-center gap-1 px-3 py-2 rounded-lg ${
                  isActive
                    ? 'text-blue-400'
                    : 'text-gray-400 hover:text-gray-300'
                }`
              }
            >
              {item.icon}
              <span className="text-xs">{item.label}</span>
              {item.pro && (
                <span className="absolute -top-1 -right-1 px-1 text-xs bg-purple-500 text-white rounded-full">
                  PRO
                </span>
              )}
            </NavLink>
          ))}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;