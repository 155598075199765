import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from './Navbar';

export const Layout: FC = () => {
  return (
    <div className="flex flex-col min-h-screen min-h-screen-dynamic bg-gray-50 dark:bg-gray-900">
      <Navbar />
      <main className="flex-1 w-full max-w-[100vw] px-4 sm:px-6 py-6 overflow-x-hidden pb-20 md:pb-6 ios-safe-area">
        <div className="container mx-auto max-w-7xl">
          <Outlet />
        </div>
      </main>
    </div>
  );
};

export default Layout;