// Generate a unique device identifier based on hardware and browser characteristics
export async function generateDeviceId(): Promise<string> {
  try {
    const components = [
      navigator.userAgent,
      navigator.language,
      new Date().getTimezoneOffset(),
      screen.colorDepth,
      navigator.hardwareConcurrency,
      screen.width,
      screen.height,
      navigator.deviceMemory,
      navigator.platform,
      // Add canvas fingerprinting
      await getCanvasFingerprint(),
      // Add WebGL fingerprinting
      await getWebGLFingerprint(),
      // Add audio fingerprinting
      await getAudioFingerprint()
    ].filter(Boolean);

    // Create a hash of all components
    const fingerprint = await crypto.subtle.digest(
      'SHA-256',
      new TextEncoder().encode(components.join('###'))
    );

    // Convert hash to hex string
    return Array.from(new Uint8Array(fingerprint))
      .map(b => b.toString(16).padStart(2, '0'))
      .join('');
  } catch (error) {
    console.error('Failed to generate device ID:', error);
    // Fallback to a simpler fingerprint if advanced methods fail
    return generateBasicDeviceId();
  }
}

// Canvas fingerprinting
async function getCanvasFingerprint(): Promise<string> {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  if (!ctx) return '';

  // Draw various shapes and text
  canvas.width = 200;
  canvas.height = 50;
  
  ctx.textBaseline = 'top';
  ctx.font = '14px Arial';
  ctx.fillStyle = '#f60';
  ctx.fillRect(125,1,62,20);
  
  ctx.fillStyle = '#069';
  ctx.fillText('DNA Vault', 2, 15);
  ctx.fillStyle = 'rgba(102, 204, 0, 0.7)';
  ctx.fillText('DNA Vault', 4, 17);

  return canvas.toDataURL();
}

// WebGL fingerprinting
async function getWebGLFingerprint(): Promise<string> {
  const canvas = document.createElement('canvas');
  const gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
  if (!gl) return '';

  const debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
  if (!debugInfo) return '';

  return [
    gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL),
    gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL)
  ].join('###');
}

// Audio fingerprinting
async function getAudioFingerprint(): Promise<string> {
  try {
    const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
    const oscillator = audioContext.createOscillator();
    const analyser = audioContext.createAnalyser();
    const gainNode = audioContext.createGain();
    const scriptProcessor = audioContext.createScriptProcessor(4096, 1, 1);

    gainNode.gain.value = 0; // Mute the sound
    oscillator.type = 'triangle';
    oscillator.connect(analyser);
    analyser.connect(scriptProcessor);
    scriptProcessor.connect(gainNode);
    gainNode.connect(audioContext.destination);

    oscillator.start(0);

    return new Promise((resolve) => {
      scriptProcessor.onaudioprocess = (e) => {
        const data = e.inputBuffer.getChannelData(0);
        const hash = data.slice(0, 50).join('');
        oscillator.stop();
        audioContext.close();
        resolve(hash);
      };
    });
  } catch {
    return '';
  }
}

// Fallback device ID generation
function generateBasicDeviceId(): string {
  const components = [
    navigator.userAgent,
    navigator.language,
    screen.colorDepth,
    screen.width,
    screen.height,
    new Date().getTimezoneOffset()
  ].join('###');

  let hash = 0;
  for (let i = 0; i < components.length; i++) {
    const char = components.charCodeAt(i);
    hash = ((hash << 5) - hash) + char;
    hash = hash & hash;
  }
  return hash.toString(36);
}