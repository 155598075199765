import { FC, ReactNode } from 'react';
import { Info } from 'lucide-react';

interface SecurityMetricProps {
  icon: ReactNode;
  label: string;
  value: number;
  color: string;
  text?: string;
  description?: string;
}

export const SecurityMetric: FC<SecurityMetricProps> = ({ 
  icon, 
  label, 
  value, 
  color, 
  text, 
  description 
}) => {
  return (
    <div>
      <div className="flex items-center justify-between mb-2">
        <div className="flex items-center gap-2">
          {icon}
          <span className="text-sm text-gray-600 dark:text-gray-400 flex items-center gap-2">
            {label}
            {description && (
              <div className="group relative">
                <Info className="h-4 w-4 text-gray-400 cursor-help" />
                <div className="absolute left-0 bottom-full mb-2 hidden group-hover:block w-64 p-2 bg-gray-800 text-xs text-white rounded shadow-lg z-10">
                  {description}
                </div>
              </div>
            )}
          </span>
        </div>
        <span className="text-sm font-medium text-gray-900 dark:text-white">
          {text || `${Math.round(value)}%`}
        </span>
      </div>
      <div className="w-full h-2 bg-gray-200 dark:bg-gray-700 rounded-full overflow-hidden">
        <div 
          className={`h-full ${color} transition-all duration-500`}
          style={{ width: `${value}%` }}
        />
      </div>
    </div>
  );
};

export default SecurityMetric;