import React, { FC } from 'react';
import { Key, CreditCard, FileText, Shield, Lock, AlertTriangle, CheckCircle2 } from 'lucide-react';
import { useVaultStore } from '../services/vaultStore';
import { StatCard } from '../components/dashboard/StatCard';
import { SecurityMetric } from '../components/dashboard/SecurityMetric';
import { SecurityAlert } from '../components/dashboard/SecurityAlert';

const Dashboard: FC = () => {
  const store = useVaultStore();
  const passwordCount = store.items.filter(item => item.category === 'login').length;
  const cardCount = store.items.filter(item => item.category === 'card').length;
  const noteCount = store.items.filter(item => item.category === 'note').length;

  // Calculate security metrics
  const weakPasswords = store.items.filter(item => 
    item.category === 'login' && item.password?.length < 12
  ).length;
  const duplicatePasswords = store.items.filter(item => 
    item.category === 'login' && 
    store.items.some(other => 
      other.id !== item.id && 
      other.category === 'login' && 
      other.password === item.password
    )
  ).length;
  const items2FA = store.items.filter(item => item.category === 'login' && item.has2FA).length;
  const reusedPasswords = store.items.filter(item =>
    item.category === 'login' &&
    store.items.some(other =>
      other.id !== item.id &&
      other.category === 'login' &&
      other.password === item.password
    )
  ).length;

  // Calculate security score
  const securityScore = Math.max(0, Math.min(100, Math.round(
    100 - 
    (weakPasswords / Math.max(1, passwordCount) * 30) -
    (duplicatePasswords / Math.max(1, passwordCount) * 30) -
    (reusedPasswords / Math.max(1, passwordCount) * 20) +
    (items2FA / Math.max(1, passwordCount) * 20)
  )));

  const statCards = [
    {
      id: 'passwords',
      icon: <Key className="h-6 w-6 text-blue-600 dark:text-blue-400" />,
      title: 'Passwords',
      count: passwordCount,
      bgColor: 'bg-blue-100 dark:bg-blue-900'
    },
    {
      id: 'cards',
      icon: <CreditCard className="h-6 w-6 text-green-600 dark:text-green-400" />,
      title: 'Cards',
      count: cardCount,
      bgColor: 'bg-green-100 dark:bg-green-900'
    },
    {
      id: 'notes',
      icon: <FileText className="h-6 w-6 text-purple-600 dark:text-purple-400" />,
      title: 'Secure Notes',
      count: noteCount,
      bgColor: 'bg-purple-100 dark:bg-purple-900'
    },
    {
      id: 'weak-passwords',
      icon: <Shield className="h-6 w-6 text-red-600 dark:text-red-400" />,
      title: 'Weak Passwords',
      count: weakPasswords,
      bgColor: 'bg-red-100 dark:bg-red-900'
    }
  ];

  const securityMetrics = [
    {
      id: 'password-strength',
      icon: <Lock className="h-4 w-4 text-gray-400" />,
      label: 'Password Strength',
      value: Math.round((passwordCount - weakPasswords) / Math.max(1, passwordCount) * 100),
      color: 'bg-green-500',
      description: 'Percentage of passwords that meet minimum security requirements (12+ characters, mixed case, numbers, symbols)'
    },
    {
      id: 'reused-passwords',
      icon: <AlertTriangle className="h-4 w-4 text-gray-400" />,
      label: 'Reused Passwords',
      value: (reusedPasswords / Math.max(1, passwordCount)) * 100,
      color: 'bg-red-500',
      text: `${reusedPasswords} found`,
      description: 'Number of passwords that are used across multiple accounts. Each password should be unique.'
    },
    {
      id: '2fa-coverage',
      icon: <Shield className="h-4 w-4 text-gray-400" />,
      label: '2FA Coverage',
      value: (items2FA / Math.max(1, passwordCount)) * 100,
      color: 'bg-blue-500',
      description: 'Percentage of accounts with two-factor authentication enabled. Higher is better for security.'
    }
  ];

  const alerts = [
    weakPasswords > 0 && {
      id: 'weak-passwords-alert',
      type: 'error' as const,
      icon: <AlertTriangle className="h-5 w-5 text-red-600 dark:text-red-400 flex-shrink-0" />,
      title: 'Weak Passwords Detected',
      message: `${weakPasswords} passwords need to be strengthened. Use at least 12 characters with a mix of uppercase, lowercase, numbers, and symbols.`
    },
    reusedPasswords > 0 && {
      id: 'reused-passwords-alert',
      type: 'warning' as const,
      icon: <AlertTriangle className="h-5 w-5 text-yellow-600 dark:text-yellow-400 flex-shrink-0" />,
      title: 'Reused Passwords Found',
      message: `${reusedPasswords} passwords are being reused across multiple accounts. Use unique passwords for better security.`
    },
    securityScore >= 80 && weakPasswords === 0 && reusedPasswords === 0 && {
      id: 'good-security-alert',
      type: 'success' as const,
      icon: <CheckCircle2 className="h-5 w-5 text-green-600 dark:text-green-400 flex-shrink-0" />,
      title: 'Good Security Practices',
      message: 'Your password security is strong. Keep up the good work by maintaining unique, strong passwords and enabling 2FA where possible.'
    }
  ].filter(Boolean);

  return (
    <div className="space-y-8">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">Welcome to DNA Vault</h1>
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {statCards.map(card => (
          <StatCard key={card.id} {...card} />
        ))}
      </div>

      <div className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-sm border border-gray-200 dark:border-gray-700">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-lg font-semibold text-gray-900 dark:text-white">Security Score</h2>
          <div className="flex items-center gap-2">
            <span className={`text-2xl font-bold ${
              securityScore >= 80 ? 'text-green-500' :
              securityScore >= 60 ? 'text-yellow-500' :
              'text-red-500'
            }`}>
              {securityScore}%
            </span>
          </div>
        </div>

        <div className="space-y-6">
          {securityMetrics.map(metric => (
            <SecurityMetric key={metric.id} {...metric} />
          ))}
        </div>

        <div className="mt-6 space-y-4">
          {alerts.map(alert => (
            <SecurityAlert key={alert.id} {...alert} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;