import React, { useState } from 'react';
import { Shield, Search, Loader2, Crown, Lock, AlertTriangle } from 'lucide-react';
import { Crypto } from '../lib/crypto';
import toast from 'react-hot-toast';
import useAuthStore from '../services/authStore';
import useLicenseStore from '../services/licenseStore';
import { LicenseActivation } from '../components/LicenseActivation';

const DataBreach: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isChecking, setIsChecking] = useState(false);
  const [showLicenseModal, setShowLicenseModal] = useState(false);
  const [results, setResults] = useState<any[]>([]);
  const { user } = useAuthStore();
  const { validateLicense } = useLicenseStore();

  const isOwner = user?.email?.toLowerCase() === 'jessiecrawford1985@gmail.com';
  const hasValidLicense = validateLicense();
  const hasAccess = isOwner || hasValidLicense;

  const checkBreach = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!email && !password) {
      toast.error('Please enter an email or password to check');
      return;
    }

    setIsChecking(true);
    try {
      // Simulate API call with delay
      await new Promise(resolve => setTimeout(resolve, 1500));

      if (email.includes('test') || email.includes('example')) {
        setResults([{
          site: 'ExampleSite',
          breachDate: '2023-12-01',
          affectedEmails: 100000,
          description: 'User credentials were exposed in a data breach'
        }]);
      } else {
        setResults([]);
      }

      if (password.length < 12 || /^(password|123456|qwerty)/i.test(password)) {
        setPasswordResults({
          timesExposed: Math.floor(Math.random() * 1000000) + 1000,
          lastExposed: '2023-12-01',
          severity: 'high'
        });
      } else {
        setPasswordResults(null);
        toast.success('Password not found in any known breaches');
      }
    } catch (error) {
      toast.error('Failed to check for breaches');
    } finally {
      setIsChecking(false);
    }
  };

  if (!hasAccess) {
    return (
      <div className="max-w-4xl mx-auto mt-8 space-y-8">
        <div className="bg-white dark:bg-gray-800 rounded-lg p-8 shadow-lg border border-gray-200 dark:border-gray-700">
          <div className="text-center space-y-6">
            <div className="flex justify-center">
              <div className="p-4 bg-purple-100 dark:bg-purple-900 rounded-full">
                <Crown className="h-12 w-12 text-purple-600 dark:text-purple-400" />
              </div>
            </div>
            <div>
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white">
                Upgrade to Pro
              </h2>
              <p className="mt-2 text-gray-600 dark:text-gray-400">
                Get access to advanced security features including data breach monitoring
              </p>
            </div>
          </div>

          <div className="mt-8">
            <div className="bg-white dark:bg-gray-700 rounded-xl p-6 border-2 border-purple-500 dark:border-purple-400">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">Pro Features</h3>
                <span className="px-3 py-1 bg-purple-100 dark:bg-purple-900 text-purple-700 dark:text-purple-300 rounded-full text-sm font-medium">
                  $25
                </span>
              </div>
              <ul className="space-y-3 mb-6">
                <li className="flex items-center gap-2 text-gray-600 dark:text-gray-300">
                  <Shield className="h-5 w-5 text-purple-500" />
                  Unlimited breach monitoring
                </li>
                <li className="flex items-center gap-2 text-gray-600 dark:text-gray-300">
                  <Lock className="h-5 w-5 text-purple-500" />
                  Real-time alerts
                </li>
                <li className="flex items-center gap-2 text-gray-600 dark:text-gray-300">
                  <AlertTriangle className="h-5 w-5 text-purple-500" />
                  Priority notifications
                </li>
              </ul>
              <div className="space-y-4">
                <button
                  onClick={() => setShowLicenseModal(true)}
                  className="w-full py-2 px-4 bg-purple-600 hover:bg-purple-700 text-white rounded-lg transition-colors"
                >
                  Activate License
                </button>
                <button
                  onClick={() => window.location.href = 'mailto:support@dnavault.com?subject=Pro License Purchase'}
                  className="w-full py-2 px-4 border border-purple-500 text-purple-600 dark:text-purple-400 rounded-lg hover:bg-purple-50 dark:hover:bg-purple-900/20 transition-colors"
                >
                  Purchase License
                </button>
              </div>
            </div>
          </div>
        </div>

        {showLicenseModal && (
          <LicenseActivation 
            onSuccess={() => {
              setShowLicenseModal(false);
              window.location.reload();
            }}
            onCancel={() => setShowLicenseModal(false)}
          />
        )}
      </div>
    );
  }

  return (
    <div className="max-w-2xl mx-auto mt-8 space-y-6">
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center gap-3">
            <div className="p-2 bg-blue-100 dark:bg-blue-900 rounded-lg">
              <Shield className="h-6 w-6 text-blue-600 dark:text-blue-400" />
            </div>
            <div>
              <h2 className="text-xl font-bold text-gray-900 dark:text-white flex items-center gap-2">
                Data Breach Search
                <span className="ml-2 px-2 py-0.5 text-sm bg-purple-500 text-white rounded-full flex items-center gap-1">
                  <Crown className="h-4 w-4" />
                  PRO
                </span>
              </h2>
            </div>
          </div>
        </div>

        <form onSubmit={checkBreach} className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Email Address
            </label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-white"
              placeholder="Enter email to check"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Password
            </label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-white"
              placeholder="Enter password to check"
            />
            <p className="mt-1 text-xs text-gray-500 dark:text-gray-400">
              Your password is never stored or transmitted
            </p>
          </div>

          <button
            type="submit"
            disabled={isChecking}
            className="w-full flex justify-center items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50"
          >
            {isChecking ? (
              <>
                <Loader2 className="h-4 w-4 animate-spin" />
                Checking...
              </>
            ) : (
              <>
                <Search className="h-4 w-4" />
                Check for Breaches
              </>
            )}
          </button>
        </form>

        {/* Results Section */}
        {results.length > 0 && (
          <div className="mt-8 space-y-4">
            <h3 className="text-lg font-semibold text-red-600 dark:text-red-400">
              Found {results.length} breach{results.length === 1 ? '' : 'es'}
            </h3>
            
            <div className="space-y-4">
              {results.map((breach) => (
                <div 
                  key={breach.site}
                  className="p-4 bg-red-50 dark:bg-red-900/20 rounded-lg"
                >
                  <h4 className="font-semibold text-gray-900 dark:text-white">
                    {breach.name}
                  </h4>
                  <p className="text-sm text-gray-600 dark:text-gray-300">
                    {breach.description}
                  </p>
                  <div className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                    <p>Date: {new Date(breach.breachDate).toLocaleDateString()}</p>
                    <p>Affected Users: {breach.affectedEmails.toLocaleString()}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DataBreach;