import toast, { ToastOptions } from 'react-hot-toast';

const toastCache = new Map<string, number>();
const TOAST_DEBOUNCE_MS = 500;

export const showToast = (message: string, type: 'success' | 'error' | 'info' = 'info', options?: ToastOptions) => {
  // Check if the same message was shown recently
  const lastShown = toastCache.get(message);
  const now = Date.now();

  if (lastShown && now - lastShown < TOAST_DEBOUNCE_MS) {
    return; // Skip showing duplicate toast
  }

  // Show the toast
  const toastFn = type === 'success' ? toast.success :
                  type === 'error' ? toast.error :
                  toast;

  toastFn(message, {
    duration: 3000,
    position: 'top-right',
    ...options
  });

  // Update the cache
  toastCache.set(message, now);

  // Clean up old cache entries
  setTimeout(() => {
    toastCache.delete(message);
  }, TOAST_DEBOUNCE_MS);
};