import React from 'react';
import { Globe, Copy, Star, CreditCard, FileText, Trash2, Edit2, ExternalLink } from 'lucide-react';
import toast from 'react-hot-toast';

interface VaultItemProps {
  item: {
    id: string;
    name: string;
    username: string;
    password?: string;
    url?: string;
    category: 'login' | 'card' | 'note';
    favorite: boolean;
    lastUsed: string;
  };
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
  onToggleFavorite: (id: string) => void;
}

export function VaultItem({ item, onEdit, onDelete, onToggleFavorite }: VaultItemProps) {
  const getFaviconUrl = (url: string) => {
    try {
      const domain = new URL(url).hostname;
      return `https://icon.horse/icon/${domain}`;
    } catch {
      return '';
    }
  };

  const copyToClipboard = async (text: string, type: string) => {
    try {
      await navigator.clipboard.writeText(text);
      toast.success(`${type} copied to clipboard`);
    } catch {
      toast.error('Failed to copy to clipboard');
    }
  };

  const handleVisitWebsite = (e: React.MouseEvent) => {
    e.preventDefault();
    if (!item.url) return;
    window.open(item.url, '_blank', 'noopener,noreferrer');
  };

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg border border-slate-200 dark:border-gray-700 p-4 hover:shadow-lg transition-all overflow-hidden group">
      <div className="flex items-start justify-between">
        <div className="flex items-center gap-3">
          <div className="p-2 bg-gray-100 dark:bg-gray-700 rounded-lg">
            {item.category === 'login' && item.url ? (
              <div className="relative w-5 h-5">
                <img
                  src={getFaviconUrl(item.url)}
                  alt=""
                  className="h-5 w-5"
                  onError={(e) => {
                    e.currentTarget.style.display = 'none';
                    e.currentTarget.parentElement?.appendChild(
                      Object.assign(document.createElement('div'), {
                        innerHTML: '<svg class="h-5 w-5 text-gray-400" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"><circle cx="12" cy="12" r="10"/><path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"/></svg>'
                      })
                    );
                  }}
                />
              </div>
            ) : item.category === 'card' ? (
              <CreditCard className="h-5 w-5 text-gray-400" />
            ) : item.category === 'note' ? (
              <FileText className="h-5 w-5 text-gray-400" />
            ) : (
              <Globe className="h-5 w-5 text-gray-400" />
            )}
          </div>
          <div>
            <h3 className="font-medium text-gray-900 dark:text-white">{item.name}</h3>
            <p className="text-sm text-gray-500 dark:text-gray-400">{item.username}</p>
          </div>
        </div>

        <div className="flex items-center gap-2 opacity-0 group-hover:opacity-100 transition-opacity">
          <button
            onClick={() => onToggleFavorite(item.id)}
            className={`p-1.5 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 ${
              item.favorite ? 'text-yellow-500' : 'text-gray-400 hover:text-gray-600'
            }`}
            title={item.favorite ? 'Remove from favorites' : 'Add to favorites'}
          >
            <Star className={`h-4 w-4 ${item.favorite ? 'fill-current' : ''}`} />
          </button>

          {item.password && (
            <button
              onClick={() => copyToClipboard(item.password!, 'Password')}
              className="p-1.5 text-gray-400 hover:text-gray-600 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700"
              title="Copy password"
            >
              <Copy className="h-4 w-4" />
            </button>
          )}

          <button
            onClick={() => onEdit(item.id)}
            className="p-1.5 text-gray-400 hover:text-gray-600 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700"
            title="Edit item"
          >
            <Edit2 className="h-4 w-4" />
          </button>

          <button
            onClick={() => onDelete(item.id)}
            className="p-1.5 text-gray-400 hover:text-red-600 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700"
            title="Delete item"
          >
            <Trash2 className="h-4 w-4" />
          </button>
        </div>
      </div>

      {item.url && (
        <div className="mt-3 flex items-center justify-between text-xs">
          <a
            href={item.url}
            onClick={handleVisitWebsite}
            className="flex items-center gap-1 text-blue-600 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-300"
          >
            <span className="truncate max-w-[200px]">{item.url}</span>
            <ExternalLink className="h-3 w-3 flex-shrink-0" />
          </a>
          <span className="text-slate-400 dark:text-slate-500">
            {new Date(item.lastUsed).toLocaleDateString()}
          </span>
        </div>
      )}
    </div>
  );
}

export default VaultItem;