import React, { useState } from 'react';
import { Key, Download, Copy, AlertTriangle } from 'lucide-react';
import RecoveryService from '../services/recoveryService';
import toast from 'react-hot-toast';

interface RecoveryKeyModalProps {
  isOpen: boolean;
  onClose: () => void;
  email: string;
}

export const RecoveryKeyModal: React.FC<RecoveryKeyModalProps> = ({
  isOpen,
  onClose,
  email
}) => {
  const [recoveryKey, setRecoveryKey] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);

  const generateRecoveryKit = async () => {
    setIsGenerating(true);
    try {
      const { recoveryKey, recoveryFile } = await RecoveryService.createRecoveryKit(email);
      setRecoveryKey(recoveryKey);

      // Create download link
      const downloadUrl = URL.createObjectURL(recoveryFile);
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = `dna-vault-recovery-${new Date().toISOString().split('T')[0]}.json`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(downloadUrl);

      toast.success('Recovery kit generated successfully');
    } catch (error) {
      toast.error('Failed to generate recovery kit');
    } finally {
      setIsGenerating(false);
    }
  };

  const copyRecoveryKey = async () => {
    try {
      await navigator.clipboard.writeText(recoveryKey);
      toast.success('Recovery key copied to clipboard');
    } catch {
      toast.error('Failed to copy recovery key');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white dark:bg-gray-800 rounded-lg w-full max-w-md p-6">
        <div className="flex items-center gap-3 mb-6">
          <div className="p-2 bg-blue-100 dark:bg-blue-900 rounded-lg">
            <Key className="h-6 w-6 text-blue-600 dark:text-blue-400" />
          </div>
          <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
            Account Recovery
          </h2>
        </div>

        <div className="space-y-4">
          <div className="bg-yellow-50 dark:bg-yellow-900/20 p-4 rounded-lg">
            <div className="flex items-center gap-2 text-yellow-800 dark:text-yellow-200">
              <AlertTriangle className="h-5 w-5" />
              <p className="text-sm">
                Keep your recovery key in a safe place. You'll need it to regain access to your account if you get locked out.
              </p>
            </div>
          </div>

          {recoveryKey ? (
            <div className="space-y-4">
              <div className="bg-gray-100 dark:bg-gray-700 p-4 rounded-lg">
                <code className="text-sm font-mono break-all">
                  {recoveryKey}
                </code>
              </div>

              <div className="flex gap-2">
                <button
                  onClick={copyRecoveryKey}
                  className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-blue-600 hover:text-blue-700 border border-blue-200 rounded-lg hover:bg-blue-50 dark:text-blue-400 dark:border-blue-800 dark:hover:bg-blue-900/20"
                >
                  <Copy className="h-4 w-4" />
                  Copy Key
                </button>
              </div>
            </div>
          ) : (
            <button
              onClick={generateRecoveryKit}
              disabled={isGenerating}
              className="w-full flex items-center justify-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50"
            >
              <Download className="h-4 w-4" />
              Generate Recovery Kit
            </button>
          )}
        </div>

        <div className="mt-6 flex justify-end">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-600 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-200"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};