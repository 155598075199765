import { Crypto } from '../lib/crypto';

export class LicenseGenerator {
  private static readonly KEY_LENGTH = 16;
  private static readonly SEGMENTS = 4;
  private static readonly CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

  static generateLicenseKey(): string {
    const segments: string[] = [];
    
    for (let i = 0; i < this.SEGMENTS; i++) {
      let segment = '';
      for (let j = 0; j < this.KEY_LENGTH / this.SEGMENTS; j++) {
        const randomIndex = Math.floor(Math.random() * this.CHARS.length);
        segment += this.CHARS[randomIndex];
      }
      segments.push(segment);
    }

    return segments.join('-');
  }

  static generateBatchKeys(count: number): string[] {
    const keys: string[] = [];
    const usedKeys = new Set<string>();

    while (keys.length < count) {
      const key = this.generateLicenseKey();
      if (!usedKeys.has(key)) {
        keys.push(key);
        usedKeys.add(key);
      }
    }

    return keys;
  }

  static validateKey(key: string): boolean {
    const keyFormat = /^[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}$/;
    return keyFormat.test(key);
  }

  static hashKey(key: string, deviceId: string): string {
    return Crypto.generateLicenseHash(key, deviceId);
  }

  static bindKeyToDevice(key: string, deviceId: string): string {
    return Crypto.hashData(`${key}:${deviceId}`);
  }
}