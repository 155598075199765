import React, { useState, useEffect } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { Smartphone, Copy, Loader2 } from 'lucide-react';
import { syncService } from '../services/syncService';
import toast from 'react-hot-toast';

export const MobileSync: React.FC = () => {
  const [syncData, setSyncData] = useState<string>('');
  const [isLoading, setIsLoading] = useState(true);
  const [qrExpiry, setQrExpiry] = useState(300); // 5 minutes in seconds

  useEffect(() => {
    const prepareSyncData = async () => {
      try {
        const data = await syncService.prepareSyncData();
        setSyncData(data);
      } catch (error) {
        console.error('Failed to prepare sync data:', error);
        toast.error('Failed to generate sync code');
      } finally {
        setIsLoading(false);
      }
    };

    prepareSyncData();

    // QR code expiry countdown
    const timer = setInterval(() => {
      setQrExpiry((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const handleCopyCode = async () => {
    try {
      await navigator.clipboard.writeText(syncData);
      toast.success('Sync code copied to clipboard');
    } catch {
      toast.error('Failed to copy sync code');
    }
  };

  const refreshCode = async () => {
    setIsLoading(true);
    try {
      const data = await syncService.prepareSyncData();
      setSyncData(data);
      setQrExpiry(300);
      toast.success('New sync code generated');
    } catch (error) {
      console.error('Failed to refresh sync code:', error);
      toast.error('Failed to generate new sync code');
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center p-8">
        <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
      </div>
    );
  }

  return (
    <div className="bg-gray-50 dark:bg-gray-700/50 p-6 rounded-lg space-y-6">
      <div className="flex items-center gap-3 mb-4">
        <Smartphone className="h-5 w-5 text-gray-400" />
        <h3 className="text-lg font-medium text-gray-900 dark:text-white">
          Sync with Mobile Device
        </h3>
      </div>

      <div className="space-y-4">
        <div className="flex justify-center bg-white dark:bg-gray-800 p-6 rounded-lg">
          {syncData && <QRCodeSVG value={syncData} size={200} includeMargin />}
        </div>

        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <code className="px-3 py-1 bg-gray-100 dark:bg-gray-800 rounded text-sm font-mono">
              {syncData.slice(0, 20)}...
            </code>
            <button
              onClick={handleCopyCode}
              className="p-2 text-gray-500 hover:text-gray-700 dark:hover:text-gray-300"
              title="Copy sync code"
            >
              <Copy className="h-4 w-4" />
            </button>
          </div>
          <span className="text-sm text-gray-500 dark:text-gray-400">
            Expires in {Math.floor(qrExpiry / 60)}:{(qrExpiry % 60).toString().padStart(2, '0')}
          </span>
        </div>

        <div className="bg-blue-50 dark:bg-blue-900/20 p-4 rounded-lg">
          <p className="text-sm text-blue-600 dark:text-blue-400">
            Scan this QR code with your mobile device or enter the sync code manually in the DNA Vault mobile app.
          </p>
        </div>

        {qrExpiry === 0 && (
          <button
            onClick={refreshCode}
            className="w-full px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
          >
            Generate New Code
          </button>
        )}
      </div>
    </div>
  );
};

export default MobileSync;