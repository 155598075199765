import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { generateUUID } from '../utils/uuid';
import { showToast } from './toastService';
import { createMigratingStorage } from '../store/baseStore';

interface VaultItem {
  id: string;
  name: string;
  username: string;
  password: string;
  url?: string;
  notes?: string;
  category: 'login' | 'card' | 'note';
  favorite: boolean;
  lastUsed: string;
  createdAt: string;
  has2FA?: boolean;
}

interface VaultState {
  version: number;
  items: VaultItem[];
  addItem: (item: Omit<VaultItem, 'id' | 'lastUsed' | 'createdAt'>) => Promise<void>;
  updateItem: (id: string, updates: Partial<VaultItem>) => Promise<void>;
  deleteItem: (id: string) => Promise<void>;
  toggleFavorite: (id: string) => Promise<void>;
  getItemById: (id: string) => VaultItem | undefined;
}

const migrations = {
  0: (state: any) => ({
    version: 1,
    items: state.items || []
  }),
  1: (state: any) => ({
    ...state,
    version: 2,
    items: state.items.map((item: VaultItem) => ({
      ...item,
      has2FA: item.has2FA ?? false
    }))
  })
};

export const useVaultStore = create<VaultState>()(
  persist(
    (set, get) => ({
      version: 2,
      items: [],

      addItem: async (item) => {
        try {
          const newItem: VaultItem = {
            ...item,
            id: generateUUID(),
            lastUsed: new Date().toISOString(),
            createdAt: new Date().toISOString(),
          };

          set((state) => ({
            items: [...state.items, newItem]
          }));

          if (item.category === 'login' && !item.has2FA) {
            showToast('Consider enabling 2FA for better security', 'info');
          }

          showToast('Item added successfully', 'success');
        } catch (error) {
          console.error('Failed to add item:', error);
          showToast('Failed to add item', 'error');
          throw error;
        }
      },

      updateItem: async (id, updates) => {
        try {
          set((state) => ({
            items: state.items.map((item) =>
              item.id === id
                ? { ...item, ...updates, lastUsed: new Date().toISOString() }
                : item
            )
          }));
          showToast('Item updated successfully', 'success');
        } catch (error) {
          console.error('Failed to update item:', error);
          showToast('Failed to update item', 'error');
          throw error;
        }
      },

      deleteItem: async (id) => {
        try {
          set((state) => ({
            items: state.items.filter((item) => item.id !== id)
          }));
          showToast('Item removed successfully', 'success');
        } catch (error) {
          console.error('Failed to remove item:', error);
          showToast('Failed to remove item', 'error');
          throw error;
        }
      },

      toggleFavorite: async (id) => {
        try {
          set((state) => ({
            items: state.items.map((item) =>
              item.id === id
                ? { ...item, favorite: !item.favorite }
                : item
            )
          }));
        } catch (error) {
          console.error('Failed to toggle favorite:', error);
          showToast('Failed to update item', 'error');
          throw error;
        }
      },

      getItemById: (id) => {
        return get().items.find((item) => item.id === id);
      },
    }),
    {
      name: 'vault-storage',
      storage: createMigratingStorage(migrations, 2),
      version: 2
    }
  )
);

export type { VaultItem };