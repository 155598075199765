import React, { useState } from 'react';
import { Settings as SettingsIcon, User, LogOut, Moon, Sun, KeyRound, Smartphone, Shield } from 'lucide-react';
import { UpdatePasswordForm } from '../components/UpdatePasswordForm';
import { ProfilePicture } from '../components/ProfilePicture';
import { DeleteAccountModal } from '../components/DeleteAccountModal';
import { MobileSync } from '../components/MobileSync';
import { RecoveryKeyModal } from '../components/RecoveryKeyModal';
import { useTheme } from '../hooks/useTheme';
import useAuthStore from '../services/authStore';

const SettingsPage: React.FC = () => {
  const { user } = useAuthStore();
  const { theme, toggleTheme } = useTheme();
  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showMobileSync, setShowMobileSync] = useState(false);
  const [showRecoveryModal, setShowRecoveryModal] = useState(false);
  const [profilePicture, setProfilePicture] = useState(localStorage.getItem('user_profile_picture') || '');

  return (
    <div className="max-w-4xl mx-auto space-y-8">
      <h1 className="text-2xl font-bold text-gray-900 dark:text-white">Settings</h1>

      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm border border-gray-200 dark:border-gray-700">
        <div className="p-6">
          <div className="flex items-center gap-4">
            <ProfilePicture 
              currentImage={profilePicture}
              onImageChange={setProfilePicture}
            />
            <div>
              <h2 className="text-xl font-semibold text-gray-900 dark:text-white">{user?.name || 'User'}</h2>
              <p className="text-gray-500 dark:text-gray-400">{user?.email}</p>
            </div>
          </div>
        </div>

        <div className="border-t border-gray-200 dark:border-gray-700">
          <div className="p-6 space-y-6">
            <div className="grid gap-6">
              {/* Password Section */}
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-3">
                  <div className="p-2 bg-blue-100 dark:bg-blue-900 rounded-lg">
                    <KeyRound className="h-5 w-5 text-blue-600 dark:text-blue-400" />
                  </div>
                  <div>
                    <h3 className="font-medium text-gray-900 dark:text-white">Password</h3>
                    <p className="text-sm text-gray-500 dark:text-gray-400">Update your account password</p>
                  </div>
                </div>
                <button
                  onClick={() => setShowPasswordForm(!showPasswordForm)}
                  className="px-4 py-2 text-sm font-medium text-blue-600 dark:text-blue-400 hover:text-blue-700 dark:hover:text-blue-300"
                >
                  {showPasswordForm ? 'Cancel' : 'Update'}
                </button>
              </div>

              {showPasswordForm && <UpdatePasswordForm onCancel={() => setShowPasswordForm(false)} />}

              {/* Mobile Sync Section */}
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-3">
                  <div className="p-2 bg-green-100 dark:bg-green-900 rounded-lg">
                    <Smartphone className="h-5 w-5 text-green-600 dark:text-green-400" />
                  </div>
                  <div>
                    <h3 className="font-medium text-gray-900 dark:text-white">Mobile Sync</h3>
                    <p className="text-sm text-gray-500 dark:text-gray-400">Sync your vault with mobile device</p>
                  </div>
                </div>
                <button
                  onClick={() => setShowMobileSync(!showMobileSync)}
                  className="px-4 py-2 text-sm font-medium text-green-600 dark:text-green-400 hover:text-green-700 dark:hover:text-green-300"
                >
                  {showMobileSync ? 'Cancel' : 'Sync'}
                </button>
              </div>

              {showMobileSync && <MobileSync />}

              {/* Account Recovery Section */}
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-3">
                  <div className="p-2 bg-purple-100 dark:bg-purple-900 rounded-lg">
                    <Shield className="h-5 w-5 text-purple-600 dark:text-purple-400" />
                  </div>
                  <div>
                    <h3 className="font-medium text-gray-900 dark:text-white">Account Recovery</h3>
                    <p className="text-sm text-gray-500 dark:text-gray-400">Set up account recovery options</p>
                  </div>
                </div>
                <button
                  onClick={() => setShowRecoveryModal(true)}
                  className="px-4 py-2 text-sm font-medium text-purple-600 dark:text-purple-400 hover:text-purple-700 dark:hover:text-purple-300"
                >
                  Generate Key
                </button>
              </div>

              {/* Theme Section */}
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-3">
                  <div className="p-2 bg-orange-100 dark:bg-orange-900 rounded-lg">
                    {theme === 'dark' ? (
                      <Moon className="h-5 w-5 text-orange-600 dark:text-orange-400" />
                    ) : (
                      <Sun className="h-5 w-5 text-orange-600 dark:text-orange-400" />
                    )}
                  </div>
                  <div>
                    <h3 className="font-medium text-gray-900 dark:text-white">Theme</h3>
                    <p className="text-sm text-gray-500 dark:text-gray-400">Toggle dark mode</p>
                  </div>
                </div>
                <button
                  onClick={toggleTheme}
                  className="px-4 py-2 text-sm font-medium text-orange-600 dark:text-orange-400 hover:text-orange-700 dark:hover:text-orange-300"
                >
                  {theme === 'dark' ? 'Switch to Light' : 'Switch to Dark'}
                </button>
              </div>

              {/* Delete Account Section */}
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-3">
                  <div className="p-2 bg-red-100 dark:bg-red-900 rounded-lg">
                    <User className="h-5 w-5 text-red-600 dark:text-red-400" />
                  </div>
                  <div>
                    <h3 className="font-medium text-gray-900 dark:text-white">Delete Account</h3>
                    <p className="text-sm text-gray-500 dark:text-gray-400">Permanently delete your account and data</p>
                  </div>
                </div>
                <button
                  onClick={() => setShowDeleteModal(true)}
                  className="px-4 py-2 text-sm font-medium text-red-600 dark:text-red-400 hover:text-red-700 dark:hover:text-red-300"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <DeleteAccountModal 
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
      />

      <RecoveryKeyModal
        isOpen={showRecoveryModal}
        onClose={() => setShowRecoveryModal(false)}
        email={user?.email || ''}
      />
    </div>
  );
};

export default SettingsPage;