import React, { useState } from 'react';
import { Plus, Search } from 'lucide-react';
import { VaultList } from '../components/vault/VaultList';
import { PasswordModal } from '../components/vault/PasswordModal';

const Vault: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between gap-4">
        <div className="relative flex-1">
          <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
          <input
            type="text"
            placeholder="Search vault..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full pl-10 pr-4 py-2 border border-gray-200 dark:border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-white"
          />
        </div>
        <button
          onClick={() => setShowModal(true)}
          className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
        >
          <Plus className="h-4 w-4" />
          Add Item
        </button>
      </div>

      <VaultList searchQuery={searchQuery} />

      <PasswordModal 
        isOpen={showModal} 
        onClose={() => setShowModal(false)}
      />
    </div>
  );
};

export default Vault;