import { FC, ReactNode } from 'react';

interface StatCardProps {
  icon: ReactNode;
  title: string;
  count: number;
  bgColor: string;
}

export const StatCard: FC<StatCardProps> = ({ icon, title, count, bgColor }) => (
  <div className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-sm border border-gray-200 dark:border-gray-700">
    <div className="flex items-center gap-4">
      <div className={`p-3 ${bgColor} rounded-lg`}>
        {icon}
      </div>
      <div>
        <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">{title}</h3>
        <p className="text-2xl font-bold text-gray-900 dark:text-white">{count}</p>
      </div>
    </div>
  </div>
);