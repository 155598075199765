import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { Crypto } from '../lib/crypto';
import { LicenseGenerator } from '../utils/licenseGenerator';
import toast from 'react-hot-toast';
import { createMigratingStorage } from '../store/baseStore';

interface License {
  key: string;
  isPaid: boolean;
  activationDate: string;
  deviceId: string;
  email: string;
  hwid: string;
}

interface LicenseState {
  version: number;
  currentLicense: License | null;
  activateLicense: (key: string, email: string) => Promise<boolean>;
  deactivateLicense: () => void;
  validateLicense: () => boolean;
  isPaidUser: () => boolean;
}

const migrations = {
  0: (state: any) => ({
    version: 1,
    currentLicense: state.currentLicense || null
  })
};

const useLicenseStore = create<LicenseState>()(
  persist(
    (set, get) => ({
      version: 1,
      currentLicense: null,

      activateLicense: async (key: string, email: string) => {
        try {
          if (!LicenseGenerator.validateKey(key)) {
            throw new Error('Invalid license key format');
          }

          const deviceId = Crypto.generateDeviceFingerprint();
          const hwid = LicenseGenerator.bindKeyToDevice(key, deviceId);
          
          const storedLicenses = JSON.parse(localStorage.getItem('valid_licenses') || '[]');
          const isValidKey = storedLicenses.includes(key);
          
          if (!isValidKey) {
            throw new Error('Invalid or unrecognized license key');
          }

          const activations = JSON.parse(localStorage.getItem('license_activations') || '{}');
          const existingActivation = activations[key];
          
          if (existingActivation && existingActivation.hwid !== hwid) {
            throw new Error('This license key is already activated on another device');
          }

          const newLicense: License = {
            key,
            isPaid: true,
            activationDate: new Date().toISOString(),
            deviceId,
            hwid,
            email: email.toLowerCase()
          };

          set({ currentLicense: newLicense });

          activations[key] = { 
            deviceId, 
            email: email.toLowerCase(),
            hwid,
            activationDate: new Date().toISOString()
          };
          localStorage.setItem('license_activations', JSON.stringify(activations));

          const userLicenses = JSON.parse(localStorage.getItem('user_licenses') || '{}');
          userLicenses[email.toLowerCase()] = 'lifetime';
          localStorage.setItem('user_licenses', JSON.stringify(userLicenses));

          toast.success('Premium features unlocked successfully');
          return true;
        } catch (error: any) {
          toast.error(error.message || 'Failed to activate license');
          return false;
        }
      },

      deactivateLicense: () => {
        const { currentLicense } = get();
        if (currentLicense) {
          const activations = JSON.parse(localStorage.getItem('license_activations') || '{}');
          delete activations[currentLicense.key];
          localStorage.setItem('license_activations', JSON.stringify(activations));

          const userLicenses = JSON.parse(localStorage.getItem('user_licenses') || '{}');
          delete userLicenses[currentLicense.email];
          localStorage.setItem('user_licenses', JSON.stringify(userLicenses));
        }
        set({ currentLicense: null });
      },

      validateLicense: () => {
        const { currentLicense } = get();
        if (!currentLicense) return false;

        try {
          const deviceId = Crypto.generateDeviceFingerprint();
          const currentHWID = LicenseGenerator.bindKeyToDevice(currentLicense.key, deviceId);
          
          if (currentHWID !== currentLicense.hwid) {
            return false;
          }

          const storedLicenses = JSON.parse(localStorage.getItem('valid_licenses') || '[]');
          if (!storedLicenses.includes(currentLicense.key)) {
            return false;
          }

          const activations = JSON.parse(localStorage.getItem('license_activations') || '{}');
          const activation = activations[currentLicense.key];
          
          return activation && activation.hwid === currentLicense.hwid;
        } catch {
          return false;
        }
      },

      isPaidUser: () => {
        const { currentLicense } = get();
        return currentLicense?.isPaid ?? false;
      }
    }),
    {
      name: 'license-storage',
      storage: createMigratingStorage(migrations, 1),
      version: 1
    }
  )
);

export default useLicenseStore;