import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { User } from '../types/user';

interface UserState {
  users: User[];
  addUser: (user: User) => void;
  updateUser: (id: string, updates: Partial<User>) => void;
  deleteUser: (id: string) => void;
  getActiveUsers: () => User[];
  getUserById: (id: string) => User | undefined;
}

export const useUserStore = create<UserState>()(
  persist(
    (set, get) => ({
      users: [],

      addUser: (user) => set((state) => {
        if (state.users.some(u => u.email === user.email)) {
          return state;
        }
        return { users: [...state.users, user] };
      }),

      updateUser: (id, updates) => set((state) => ({
        users: state.users.map(user => 
          user.id === id ? { ...user, ...updates } : user
        )
      })),

      deleteUser: (id) => set((state) => ({
        users: state.users.filter(user => user.id !== id)
      })),

      getActiveUsers: () => get().users,
      
      getUserById: (id) => get().users.find(user => user.id === id)
    }),
    {
      name: 'user-storage',
      storage: createJSONStorage(() => localStorage),
      partialize: (state) => ({ users: state.users })
    }
  )
);

export default useUserStore;