import React, { useState } from 'react';
import { AlertTriangle, Lock, Loader2 } from 'lucide-react';
import useAuthStore from '../services/authService';
import { useUserStore } from '../store/userStore';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

interface DeleteAccountModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const DeleteAccountModal: React.FC<DeleteAccountModalProps> = ({ isOpen, onClose }) => {
  const [password, setPassword] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { logout, user } = useAuthStore();
  const userStore = useUserStore();
  const navigate = useNavigate();

  const handleDelete = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    if (!password) {
      setError('Please enter your password');
      return;
    }

    setIsDeleting(true);
    try {
      // Mark user as inactive instead of deleting
      if (user?.id) {
        userStore.updateUser(user.id, {
          status: 'inactive',
          lastLogin: new Date().toISOString()
        });

        // Remove user's license if they have one
        const userEmail = user.email.toLowerCase();
        const userLicenses = JSON.parse(localStorage.getItem('user_licenses') || '{}');
        if (userLicenses[userEmail]) {
          delete userLicenses[userEmail];
          localStorage.setItem('user_licenses', JSON.stringify(userLicenses));
        }
      }

      // Clear local storage except for persisted user data
      const userStorage = localStorage.getItem('user-storage');
      localStorage.clear();
      if (userStorage) {
        localStorage.setItem('user-storage', userStorage);
      }

      logout();
      toast.success('Account deleted successfully');
      navigate('/register');
    } catch (error: any) {
      setError(error.message || 'Failed to delete account');
    } finally {
      setIsDeleting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white dark:bg-gray-800 rounded-lg w-full max-w-md p-6">
        <div className="flex items-center gap-3 mb-6">
          <div className="p-2 bg-red-100 dark:bg-red-900 rounded-lg">
            <AlertTriangle className="h-6 w-6 text-red-600 dark:text-red-400" />
          </div>
          <h2 className="text-xl font-bold text-gray-900 dark:text-white">
            Delete Account
          </h2>
        </div>

        <div className="mb-6">
          <p className="text-gray-600 dark:text-gray-400">
            This action cannot be undone. Your account will be deactivated and all your data will be permanently deleted.
          </p>
        </div>

        <form onSubmit={handleDelete} className="space-y-4">
          {error && (
            <div className="p-4 bg-red-100 dark:bg-red-900 text-red-700 dark:text-red-300 rounded-lg">
              {error}
            </div>
          )}

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Confirm your password
            </label>
            <div className="relative">
              <Lock className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="block w-full pl-10 pr-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-red-500 dark:bg-gray-700 dark:text-white"
                placeholder="Enter your password"
                disabled={isDeleting}
              />
            </div>
          </div>

          <div className="flex justify-end gap-3 pt-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white"
              disabled={isDeleting}
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isDeleting || !password}
              className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-lg hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 disabled:opacity-50"
            >
              {isDeleting ? (
                <>
                  <Loader2 className="h-4 w-4 animate-spin" />
                  Deleting...
                </>
              ) : (
                'Delete Account'
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};