export interface Website {
  domain: string;
  url: string;
  authority: number;
}

export const COMMON_WEBSITES: Record<string, string> = {
  'Google': 'https://www.google.com',
  'Facebook': 'https://www.facebook.com',
  'Twitter': 'https://twitter.com',
  'LinkedIn': 'https://www.linkedin.com',
  'Instagram': 'https://www.instagram.com',
  'YouTube': 'https://www.youtube.com',
  'Amazon': 'https://www.amazon.com',
  'Netflix': 'https://www.netflix.com',
  'Spotify': 'https://www.spotify.com',
  'Microsoft': 'https://www.microsoft.com',
  'Apple': 'https://www.apple.com',
  'GitHub': 'https://github.com',
  'Reddit': 'https://www.reddit.com',
  'Twitch': 'https://www.twitch.tv',
  'Pinterest': 'https://www.pinterest.com',
  'Discord': 'https://discord.com',
  'Dropbox': 'https://www.dropbox.com',
  'PayPal': 'https://www.paypal.com',
  'Steam': 'https://store.steampowered.com'
};

export const EMAIL_DOMAINS: Record<string, string> = {
  'gmail.com': 'https://mail.google.com',
  'outlook.com': 'https://outlook.live.com',
  'yahoo.com': 'https://mail.yahoo.com',
  'hotmail.com': 'https://outlook.live.com',
  'aol.com': 'https://mail.aol.com',
  'icloud.com': 'https://www.icloud.com/mail'
};

export function getUrlSuggestionFromEmail(email: string): string | undefined {
  const domain = email.split('@')[1]?.toLowerCase();
  if (!domain) return undefined;

  // Check email providers first
  if (EMAIL_DOMAINS[domain]) {
    return EMAIL_DOMAINS[domain];
  }

  // Check if domain matches any common website
  const matchingWebsite = Object.entries(COMMON_WEBSITES).find(([name, url]) => 
    url.toLowerCase().includes(domain) || domain.includes(name.toLowerCase())
  );

  return matchingWebsite?.[1];
}

export function getUrlSuggestions(input: string): string[] {
  if (!input) return [];
  
  const lowercaseInput = input.toLowerCase();
  const suggestions = new Set<string>();

  // Add matching common websites
  Object.values(COMMON_WEBSITES).forEach(url => {
    if (url.toLowerCase().includes(lowercaseInput)) {
      suggestions.add(url);
    }
  });

  // Add matching email providers
  Object.values(EMAIL_DOMAINS).forEach(url => {
    if (url.toLowerCase().includes(lowercaseInput)) {
      suggestions.add(url);
    }
  });

  return Array.from(suggestions).slice(0, 5);
}