import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { User } from '../types/user';
import toast from 'react-hot-toast';
import { createMigratingStorage } from '../store/baseStore';

interface AuthState {
  version: number;
  user: User | null;
  isAuthenticated: boolean;
  isLoading: boolean;
  error: string | null;
  register: (email: string, password: string, name: string) => Promise<void>;
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
}

const migrations = {
  0: (state: any) => ({
    version: 1,
    user: state.user || null,
    isAuthenticated: state.isAuthenticated ?? false,
    isLoading: false,
    error: null
  })
};

const useAuthStore = create<AuthState>()(
  persist(
    (set) => ({
      version: 1,
      user: null,
      isAuthenticated: false,
      isLoading: false,
      error: null,

      register: async (email: string, password: string, name: string) => {
        set({ isLoading: true, error: null });
        
        try {
          const normalizedEmail = email.toLowerCase().trim();

          const newUser: User = {
            id: crypto.randomUUID(),
            email: normalizedEmail,
            name: name.trim(),
            lastLogin: new Date().toISOString(),
            failedAttempts: 0,
            has2FA: false,
            createdAt: new Date().toISOString()
          };

          const credentials = { [normalizedEmail]: password };
          localStorage.setItem('user_credentials', JSON.stringify(credentials));

          const users = JSON.parse(localStorage.getItem('users') || '[]');
          users.push(newUser);
          localStorage.setItem('users', JSON.stringify(users));

          set({
            user: newUser,
            isAuthenticated: true,
            isLoading: false,
            error: null
          });

        } catch (error: any) {
          set({
            isLoading: false,
            error: error.message || 'Registration failed'
          });
          throw error;
        }
      },

      login: async (email: string, password: string) => {
        set({ isLoading: true, error: null });

        try {
          const normalizedEmail = email.toLowerCase().trim();
          const credentials = JSON.parse(localStorage.getItem('user_credentials') || '{}');
          
          if (!credentials[normalizedEmail] || credentials[normalizedEmail] !== password) {
            throw new Error('Invalid email or password');
          }

          const users = JSON.parse(localStorage.getItem('users') || '[]');
          const user = users.find((u: User) => u.email.toLowerCase() === normalizedEmail);

          if (!user) {
            throw new Error('User not found');
          }

          set({
            user,
            isAuthenticated: true,
            isLoading: false,
            error: null
          });

          toast.success('Login successful');
        } catch (error: any) {
          set({
            isLoading: false,
            error: error.message || 'Login failed'
          });
          throw error;
        }
      },

      logout: () => {
        localStorage.removeItem('auth-storage');
        set({
          user: null,
          isAuthenticated: false,
          isLoading: false,
          error: null
        });
        toast.success('Logged out successfully');
      }
    }),
    {
      name: 'auth-storage',
      storage: createMigratingStorage(migrations, 1),
      version: 1
    }
  )
);

export default useAuthStore;