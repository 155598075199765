import React, { useRef } from 'react';
import { Camera, X } from 'lucide-react';
import toast from 'react-hot-toast';

interface ProfilePictureProps {
  currentImage?: string;
  onImageChange: (image: string) => void;
}

export const ProfilePicture: React.FC<ProfilePictureProps> = ({ currentImage, onImageChange }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    if (!file.type.startsWith('image/')) {
      toast.error('Please select an image file');
      return;
    }

    if (file.size > 5 * 1024 * 1024) {
      toast.error('Image must be less than 5MB');
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const dataUrl = e.target?.result as string;
      onImageChange(dataUrl);
      localStorage.setItem('user_profile_picture', dataUrl);
      toast.success('Profile picture updated');
    };
    reader.readAsDataURL(file);
  };

  const handleRemoveImage = () => {
    onImageChange('');
    localStorage.removeItem('user_profile_picture');
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    toast.success('Profile picture removed');
  };

  return (
    <div className="flex flex-col items-center gap-4">
      <div className="relative">
        {currentImage ? (
          <div className="relative group">
            <img
              src={currentImage}
              alt="Profile"
              className="h-24 w-24 rounded-full object-cover border-2 border-gray-200 dark:border-gray-700"
            />
            <button
              onClick={handleRemoveImage}
              className="absolute -top-2 -right-2 p-1 bg-red-500 text-white rounded-full opacity-0 group-hover:opacity-100 transition-opacity"
              title="Remove image"
            >
              <X className="h-4 w-4" />
            </button>
          </div>
        ) : (
          <div className="h-24 w-24 rounded-full bg-gray-200 dark:bg-gray-700 flex items-center justify-center">
            <Camera className="h-8 w-8 text-gray-400 dark:text-gray-500" />
          </div>
        )}
      </div>

      <div className="flex flex-col items-center gap-2">
        <input
          ref={fileInputRef}
          type="file"
          accept="image/*"
          onChange={handleFileSelect}
          className="hidden"
          id="profile-picture-input"
        />
        <label
          htmlFor="profile-picture-input"
          className="px-4 py-2 text-sm font-medium text-blue-600 hover:text-blue-700 border border-blue-200 rounded-lg hover:bg-blue-50 dark:text-blue-400 dark:border-blue-800 dark:hover:bg-blue-900/50 cursor-pointer"
        >
          {currentImage ? 'Change Picture' : 'Upload Picture'}
        </label>
        <p className="text-xs text-gray-500 dark:text-gray-400">
          JPG, PNG or GIF (max. 5MB)
        </p>
      </div>
    </div>
  );
};