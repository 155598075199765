export function generateUUID(): string {
  if (typeof crypto.randomUUID === 'function') {
    return crypto.randomUUID();
  }

  const getRandomBits = (size: number) => {
    const randomBytes = new Uint8Array(size);
    crypto.getRandomValues(randomBytes);
    return randomBytes;
  };

  const byteToHex: string[] = [];
  for (let i = 0; i < 256; i++) {
    byteToHex[i] = (i + 0x100).toString(16).slice(1);
  }

  const randomBytes = getRandomBits(16);
  
  randomBytes[6] = (randomBytes[6] & 0x0f) | 0x40;
  randomBytes[8] = (randomBytes[8] & 0x3f) | 0x80;

  let uuid = '';
  for (let i = 0; i < 16; i++) {
    if (i === 4 || i === 6 || i === 8 || i === 10) {
      uuid += '-';
    }
    uuid += byteToHex[randomBytes[i]];
  }

  return uuid;
}