import React from 'react';
import KeyGenerator from '../components/KeyGenerator';
import useAuthStore from '../services/authStore';

const KeyManager: React.FC = () => {
  const { user } = useAuthStore();
  const isOwner = user?.email?.toLowerCase() === 'jessiecrawford1985@gmail.com';

  if (!isOwner) {
    return null;
  }

  return (
    <div className="max-w-4xl mx-auto mt-8">
      <KeyGenerator />
    </div>
  );
};

export default KeyManager;