import { CryptoService } from './cryptoService';
import { storage } from './storageService';
import { generateDeviceId } from '../utils/deviceId';
import toast from 'react-hot-toast';

class RecoveryService {
  private static readonly RECOVERY_KEY_LENGTH = 16;
  private static readonly RECOVERY_KEY_PREFIX = 'DNV';

  static generateRecoveryKey(): string {
    const randomBytes = new Uint8Array(this.RECOVERY_KEY_LENGTH);
    crypto.getRandomValues(randomBytes);
    const key = Array.from(randomBytes, byte => byte.toString(16).padStart(2, '0')).join('');
    return `${this.RECOVERY_KEY_PREFIX}-${key}`;
  }

  static async createRecoveryKit(email: string): Promise<{ 
    recoveryKey: string;
    recoveryFile: Blob;
  }> {
    try {
      const recoveryKey = this.generateRecoveryKey();
      const hashedKey = await CryptoService.hashPassword(recoveryKey);
      const deviceId = await generateDeviceId();
      
      // Store recovery information
      const recoveryData = {
        email: email.toLowerCase(),
        keyHash: hashedKey,
        deviceId,
        createdAt: new Date().toISOString()
      };

      // Save to local storage (encrypted)
      const recoveryStore = JSON.parse(localStorage.getItem('recovery_data') || '{}');
      recoveryStore[email.toLowerCase()] = recoveryData;
      localStorage.setItem('recovery_data', JSON.stringify(recoveryStore));

      // Generate recovery file content
      const recoveryFileContent = {
        email: email.toLowerCase(),
        recoveryKey,
        deviceId,
        createdAt: new Date().toISOString(),
        instructions: [
          "Keep this file in a secure location.",
          "Do not share your recovery key with anyone.",
          "Recovery is only possible from the device where the account was created.",
          "Use this key only when you need to recover your account."
        ]
      };

      // Create downloadable recovery file
      const blob = new Blob([JSON.stringify(recoveryFileContent, null, 2)], { 
        type: 'application/json' 
      });

      return { recoveryKey, recoveryFile: blob };
    } catch (error) {
      console.error('Failed to create recovery kit:', error);
      throw new Error('Failed to create account recovery kit');
    }
  }

  static async recoverAccount(email: string, recoveryKey: string): Promise<boolean> {
    try {
      const recoveryStore = JSON.parse(localStorage.getItem('recovery_data') || '{}');
      const recoveryData = recoveryStore[email.toLowerCase()];

      if (!recoveryData) {
        throw new Error('No recovery data found for this account');
      }

      // Verify device ID
      const currentDeviceId = await generateDeviceId();
      if (currentDeviceId !== recoveryData.deviceId) {
        throw new Error('Account recovery must be performed on the original device');
      }

      const hashedKey = await CryptoService.hashPassword(recoveryKey);
      if (hashedKey !== recoveryData.keyHash) {
        throw new Error('Invalid recovery key');
      }

      // Generate temporary password
      const tempPassword = Array.from(crypto.getRandomValues(new Uint8Array(12)))
        .map(byte => byte.toString(16).padStart(2, '0'))
        .join('')
        .slice(0, 12);

      // Update user credentials with temporary password
      const userCredentials = JSON.parse(localStorage.getItem('user_credentials') || '{}');
      userCredentials[email.toLowerCase()] = await CryptoService.hashPassword(tempPassword);
      localStorage.setItem('user_credentials', JSON.stringify(userCredentials));

      toast.success(`Account recovered successfully! Your temporary password is: ${tempPassword}`);
      return true;
    } catch (error) {
      console.error('Account recovery failed:', error);
      throw error;
    }
  }

  static async validateRecoveryKey(key: string): Promise<boolean> {
    return key.startsWith(this.RECOVERY_KEY_PREFIX) && key.length === this.RECOVERY_KEY_PREFIX.length + 1 + (this.RECOVERY_KEY_LENGTH * 2);
  }
}

export default RecoveryService;