import { StateStorage } from 'zustand/middleware';

interface MigratableState {
  version: number;
}

export const createMigratingStorage = <T extends MigratableState>(
  migrations: Record<number, (state: any) => T>,
  currentVersion: number
): StateStorage => ({
  getItem: (name: string): string | null => {
    try {
      const value = localStorage.getItem(name);
      if (!value) return null;

      const parsed = JSON.parse(value);
      const version = parsed.state?.version ?? 0;

      if (version < currentVersion) {
        let migratedState = parsed.state;
        for (let v = version; v < currentVersion; v++) {
          if (migrations[v]) {
            migratedState = migrations[v](migratedState);
          }
        }
        return JSON.stringify({ state: migratedState });
      }

      return value;
    } catch {
      return null;
    }
  },
  setItem: (name: string, value: string): void => {
    try {
      localStorage.setItem(name, value);
    } catch {
      console.warn('Failed to save state to localStorage');
    }
  },
  removeItem: (name: string): void => {
    try {
      localStorage.removeItem(name);
    } catch {
      console.warn('Failed to remove state from localStorage');
    }
  }
});