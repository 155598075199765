import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { CryptoService } from './cryptoService';
import { User } from '../types/user';
import toast from 'react-hot-toast';

interface AuthState {
  user: User | null;
  isAuthenticated: boolean;
  isLoading: boolean;
  error: string | null;
  register: (email: string, password: string, name: string) => Promise<void>;
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
}

const useAuthStore = create<AuthState>()(
  persist(
    (set) => ({
      user: null,
      isAuthenticated: false,
      isLoading: false,
      error: null,

      register: async (email: string, password: string, name: string) => {
        set({ isLoading: true, error: null });
        
        try {
          // Validate inputs
          if (!email || !password || !name) {
            throw new Error('All fields are required');
          }

          const normalizedEmail = email.toLowerCase().trim();

          // Check if user exists
          const users = JSON.parse(localStorage.getItem('users') || '[]');
          if (users.some((u: User) => u.email.toLowerCase() === normalizedEmail)) {
            throw new Error('Email already registered');
          }

          // Create user object
          const newUser: User = {
            id: CryptoService.generateSecureId(),
            email: normalizedEmail,
            name: name.trim(),
            role: 'user',
            status: 'active',
            lastLogin: new Date().toISOString(),
            failedAttempts: 0,
            has2FA: false,
            createdAt: new Date().toISOString(),
            license: 'free'
          };

          // Hash password and store credentials
          const hashedPassword = await CryptoService.hashPassword(password);
          const credentials = JSON.parse(localStorage.getItem('user_credentials') || '{}');
          credentials[normalizedEmail] = hashedPassword;

          // Save to localStorage
          users.push(newUser);
          localStorage.setItem('users', JSON.stringify(users));
          localStorage.setItem('user_credentials', JSON.stringify(credentials));

          // Update state
          set({
            user: newUser,
            isAuthenticated: true,
            isLoading: false,
            error: null
          });

          toast.success('Account created successfully');
        } catch (error: any) {
          set({
            isLoading: false,
            error: error.message || 'Registration failed'
          });
          toast.error(error.message || 'Registration failed');
          throw error;
        }
      },

      login: async (email: string, password: string) => {
        set({ isLoading: true, error: null });

        try {
          const normalizedEmail = email.toLowerCase().trim();
          const hashedPassword = await CryptoService.hashPassword(password);

          // Verify credentials
          const credentials = JSON.parse(localStorage.getItem('user_credentials') || '{}');
          const storedHash = credentials[normalizedEmail];

          if (!storedHash || storedHash !== hashedPassword) {
            throw new Error('Invalid email or password');
          }

          // Get user data
          const users = JSON.parse(localStorage.getItem('users') || '[]');
          const user = users.find((u: User) => u.email.toLowerCase() === normalizedEmail);

          if (!user) {
            throw new Error('User not found');
          }

          // Update last login
          user.lastLogin = new Date().toISOString();
          localStorage.setItem('users', JSON.stringify(users));

          set({
            user,
            isAuthenticated: true,
            isLoading: false,
            error: null
          });

          toast.success('Login successful');
        } catch (error: any) {
          set({
            isLoading: false,
            error: error.message || 'Login failed'
          });
          toast.error(error.message || 'Login failed');
          throw error;
        }
      },

      logout: () => {
        localStorage.removeItem('auth-storage');
        set({
          user: null,
          isAuthenticated: false,
          error: null
        });
        toast.success('Logged out successfully');
      }
    }),
    {
      name: 'auth-storage',
      partialize: (state) => ({
        user: state.user,
        isAuthenticated: state.isAuthenticated
      })
    }
  )
);

export default useAuthStore;