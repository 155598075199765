import { FC, ReactNode } from 'react';

interface SecurityAlertProps {
  type: 'error' | 'warning' | 'success';
  icon: ReactNode;
  title: string;
  message: string;
}

export const SecurityAlert: FC<SecurityAlertProps> = ({ type, icon, title, message }) => (
  <div className={`flex items-start gap-3 p-4 rounded-lg ${
    type === 'error' ? 'bg-red-100 dark:bg-red-900/50' :
    type === 'warning' ? 'bg-yellow-100 dark:bg-yellow-900/50' :
    'bg-green-100 dark:bg-green-900/50'
  }`}>
    {icon}
    <div>
      <p className={`text-sm font-medium ${
        type === 'error' ? 'text-red-800 dark:text-red-200' :
        type === 'warning' ? 'text-yellow-800 dark:text-yellow-200' :
        'text-green-800 dark:text-green-200'
      }`}>
        {title}
      </p>
      <p className={`text-xs ${
        type === 'error' ? 'text-red-600 dark:text-red-300' :
        type === 'warning' ? 'text-yellow-600 dark:text-yellow-300' :
        'text-green-600 dark:text-green-300'
      }`}>
        {message}
      </p>
    </div>
  </div>
);