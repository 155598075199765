import React, { useState, useEffect } from 'react';
import { Key, Copy, Download, AlertTriangle } from 'lucide-react';
import { LicenseGenerator } from '../utils/licenseGenerator';
import useAuthStore from '../services/authStore';
import toast from 'react-hot-toast';

const KeyGenerator: React.FC = () => {
  const [keys, setKeys] = useState<string[]>([]);
  const [count, setCount] = useState(1);
  const { user } = useAuthStore();

  const isOwner = user?.email?.toLowerCase() === 'jessiecrawford1985@gmail.com';

  useEffect(() => {
    if (!isOwner) {
      toast.error('Only the owner can generate license keys');
      window.location.href = '/';
    }
  }, [isOwner]);

  const generateKeys = () => {
    if (!isOwner) {
      toast.error('Only the owner can generate license keys');
      return;
    }

    const newKeys = LicenseGenerator.generateBatchKeys(count);
    
    // Store generated keys in valid licenses
    const validLicenses = JSON.parse(localStorage.getItem('valid_licenses') || '[]');
    const updatedLicenses = [...validLicenses, ...newKeys];
    localStorage.setItem('valid_licenses', JSON.stringify(updatedLicenses));
    
    setKeys(newKeys);
    toast.success(`Generated ${count} new license keys`);
  };

  const copyKey = async (key: string) => {
    try {
      await navigator.clipboard.writeText(key);
      toast.success('License key copied');
    } catch {
      toast.error('Failed to copy key');
    }
  };

  const downloadKeys = () => {
    const content = keys.join('\n');
    const blob = new Blob([content], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `license-keys-${new Date().toISOString().split('T')[0]}.txt`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  if (!isOwner) {
    return null;
  }

  return (
    <div className="p-6">
      <div className="flex items-center gap-3 mb-6">
        <div className="p-2 bg-blue-100 dark:bg-blue-900 rounded-lg">
          <Key className="h-6 w-6 text-blue-600 dark:text-blue-400" />
        </div>
        <h2 className="text-xl font-bold text-gray-900 dark:text-white">
          License Key Generator
        </h2>
      </div>

      <div className="bg-yellow-50 dark:bg-yellow-900/20 p-4 rounded-lg mb-6">
        <div className="flex items-center gap-2 text-yellow-800 dark:text-yellow-200">
          <AlertTriangle className="h-5 w-5" />
          <p className="text-sm">
            Generated keys are HWID-locked and can only be used on one device
          </p>
        </div>
      </div>

      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
            Number of Keys
          </label>
          <input
            type="number"
            min="1"
            max="100"
            value={count}
            onChange={(e) => setCount(parseInt(e.target.value) || 1)}
            className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg dark:bg-gray-700 dark:text-white"
          />
        </div>

        <button
          onClick={generateKeys}
          className="w-full px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
        >
          Generate Keys
        </button>

        {keys.length > 0 && (
          <div className="space-y-4">
            <div className="border border-gray-200 dark:border-gray-700 rounded-lg divide-y divide-gray-200 dark:divide-gray-700">
              {keys.map((key, index) => (
                <div 
                  key={index}
                  className="p-3 flex items-center justify-between"
                >
                  <code className="font-mono text-sm">{key}</code>
                  <button
                    onClick={() => copyKey(key)}
                    className="p-1 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300"
                    title="Copy key"
                  >
                    <Copy className="h-4 w-4" />
                  </button>
                </div>
              ))}
            </div>

            <button
              onClick={downloadKeys}
              className="w-full flex items-center justify-center gap-2 px-4 py-2 text-blue-600 border border-blue-200 rounded-lg hover:bg-blue-50 dark:text-blue-400 dark:border-blue-800 dark:hover:bg-blue-900/20"
            >
              <Download className="h-4 w-4" />
              Download Keys
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default KeyGenerator;