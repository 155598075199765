import { StateCreator, create } from 'zustand';
import { persist, createJSONStorage, PersistOptions } from 'zustand/middleware';

interface BaseState {
  _version: number;
}

export const createVersionedStore = <T extends BaseState>(
  initialState: T,
  storeName: string,
  stateCreator: StateCreator<T>,
  migrations?: Record<number, (state: any) => Partial<T>>
) => {
  type PersistedState = Omit<T, keyof BaseState>;

  const persistOptions: PersistOptions<T> = {
    name: storeName,
    storage: createJSONStorage(() => localStorage),
    version: initialState._version,
    migrate: (persistedState: any, version) => {
      let state = persistedState as T;

      if (version < initialState._version && migrations) {
        for (let v = version; v < initialState._version; v++) {
          if (migrations[v]) {
            state = {
              ...state,
              ...migrations[v](state),
              _version: v + 1
            };
          }
        }
      }

      return {
        ...initialState,
        ...state,
        _version: initialState._version
      };
    }
  };

  return create<T>()(
    persist(
      stateCreator,
      persistOptions
    )
  );
};