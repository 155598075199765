import { storage } from './storageService';
import { Encryption } from '../lib/encryption';
import { generateUUID } from '../utils/uuid';
import toast from 'react-hot-toast';

class SyncService {
  private readonly SYNC_INTERVAL = 30000; // 30 seconds
  private readonly DEVICE_ID = crypto.randomUUID();
  private syncTimer: NodeJS.Timeout | null = null;
  private lastSync: number = 0;

  async prepareSyncData(): Promise<string> {
    try {
      // Get all vault items
      const items = await storage.getAllVaultItems();
      
      // Prepare sync payload
      const syncPayload = {
        id: generateUUID(),
        timestamp: Date.now(),
        deviceId: this.DEVICE_ID,
        items,
        metadata: {
          version: '1.0.0',
          platform: 'web',
          created: new Date().toISOString()
        }
      };

      // Encrypt the payload
      const encryptedData = Encryption.encryptData(syncPayload);
      
      // Generate a temporary sync token
      const syncToken = generateUUID();
      
      // Store the encrypted data with the token
      localStorage.setItem(`sync_${syncToken}`, encryptedData);
      
      // Set expiry (5 minutes)
      setTimeout(() => {
        localStorage.removeItem(`sync_${syncToken}`);
      }, 5 * 60 * 1000);

      return syncToken;
    } catch (error) {
      console.error('Failed to prepare sync data:', error);
      throw new Error('Failed to prepare sync data');
    }
  }

  async startSync(): Promise<void> {
    if (this.syncTimer) {
      clearInterval(this.syncTimer);
    }

    // Initial sync
    await this.performSync();

    // Set up periodic sync
    this.syncTimer = setInterval(() => {
      this.performSync().catch(error => {
        console.error('Sync failed:', error);
      });
    }, this.SYNC_INTERVAL);
  }

  private async performSync(): Promise<void> {
    try {
      // Get local changes since last sync
      const localChanges = await this.getLocalChanges();

      // Update last sync timestamp
      this.lastSync = Date.now();
      await this.updateSyncMetadata();

      // Notify user of successful sync
      toast.success('Vault synced successfully');
    } catch (error) {
      console.error('Sync failed:', error);
      toast.error('Failed to sync vault');
      throw error;
    }
  }

  private async getLocalChanges(): Promise<any[]> {
    try {
      const changes = await storage.getAllVaultItems();
      return changes.filter(item => item.lastModified > this.lastSync);
    } catch (error) {
      console.error('Failed to get local changes:', error);
      return [];
    }
  }

  private async updateSyncMetadata(): Promise<void> {
    try {
      await storage.saveVaultItem({
        id: 'sync-metadata',
        lastSync: this.lastSync,
        deviceId: this.DEVICE_ID,
        changes: []
      });
    } catch (error) {
      console.error('Failed to update sync metadata:', error);
    }
  }

  stopSync(): void {
    if (this.syncTimer) {
      clearInterval(this.syncTimer);
      this.syncTimer = null;
    }
  }
}

export const syncService = new SyncService();