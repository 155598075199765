import { createVersionedStore } from './createStore';

interface ThemeState {
  _version: number;
  isDark: boolean;
  toggleTheme: () => void;
}

const initialState: ThemeState = {
  _version: 1,
  isDark: true, // Default to dark theme
  toggleTheme: () => {}
};

const migrations = {
  0: (state: any) => ({
    isDark: true // Ensure dark theme on migration
  })
};

export const useThemeStore = createVersionedStore<ThemeState>(
  initialState,
  'theme-storage',
  (set) => ({
    ...initialState,
    toggleTheme: () => set((state) => {
      const newIsDark = !state.isDark;
      if (newIsDark) {
        document.documentElement.classList.add('dark');
      } else {
        document.documentElement.classList.remove('dark');
      }
      return { isDark: newIsDark };
    })
  }),
  migrations
);