import CryptoJS from 'crypto-js';

export class Crypto {
  static hashPassword(password: string): string {
    return CryptoJS.SHA256(password).toString();
  }

  static hashData(data: string): string {
    return CryptoJS.SHA256(data).toString();
  }

  static encryptData(data: string, key: string): string {
    return CryptoJS.AES.encrypt(data, key).toString();
  }

  static decryptData(encryptedData: string, key: string): string {
    const bytes = CryptoJS.AES.decrypt(encryptedData, key);
    return bytes.toString(CryptoJS.enc.Utf8);
  }

  static generateDeviceFingerprint(): string {
    const components = [
      navigator.userAgent,
      navigator.language,
      screen.colorDepth,
      screen.width,
      screen.height,
      new Date().getTimezoneOffset(),
      navigator.hardwareConcurrency,
      navigator.platform,
      !!navigator.cookieEnabled
    ];

    return CryptoJS.SHA256(components.join('###')).toString();
  }

  static validateLicenseKey(key: string): boolean {
    // License key format: XXXX-XXXX-XXXX-XXXX where X is alphanumeric
    const keyFormat = /^[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}$/;
    return keyFormat.test(key);
  }

  static generateLicenseHash(key: string, deviceId: string): string {
    return CryptoJS.SHA256(key + '###' + deviceId).toString();
  }
}