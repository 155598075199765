import React, { useState } from 'react';
import { Key, Loader2, Shield } from 'lucide-react';
import useLicenseStore from '../services/licenseStore';
import useAuthStore from '../services/authStore';
import toast from 'react-hot-toast';

interface LicenseActivationProps {
  onSuccess?: () => void;
  onCancel?: () => void;
}

export const LicenseActivation: React.FC<LicenseActivationProps> = ({ 
  onSuccess,
  onCancel 
}) => {
  const [licenseKey, setLicenseKey] = useState('');
  const [isActivating, setIsActivating] = useState(false);
  const { activateLicense } = useLicenseStore();
  const { user } = useAuthStore();

  const handleActivate = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!licenseKey || !user?.email) return;

    setIsActivating(true);
    try {
      const success = await activateLicense(licenseKey, user.email);
      if (success) {
        toast.success('License activated successfully');
        onSuccess?.();
      }
    } catch (error) {
      console.error('License activation failed:', error);
      toast.error('Failed to activate license');
    } finally {
      setIsActivating(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white dark:bg-gray-800 rounded-lg w-full max-w-md p-6">
        <div className="flex items-center gap-3 mb-6">
          <div className="p-2 bg-blue-100 dark:bg-blue-900 rounded-lg">
            <Key className="h-6 w-6 text-blue-600 dark:text-blue-400" />
          </div>
          <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
            Activate License
          </h2>
        </div>

        <form onSubmit={handleActivate} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              License Key
            </label>
            <input
              type="text"
              value={licenseKey}
              onChange={(e) => setLicenseKey(e.target.value.trim().toUpperCase())}
              className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-white"
              placeholder="XXXX-XXXX-XXXX-XXXX"
              pattern="[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}"
              maxLength={19}
            />
          </div>

          <div className="bg-blue-50 dark:bg-blue-900/20 p-4 rounded-lg">
            <div className="flex items-center gap-2">
              <Shield className="h-5 w-5 text-blue-500" />
              <p className="text-sm text-blue-600 dark:text-blue-400">
                This license will be bound to your current device
              </p>
            </div>
          </div>

          <div className="flex justify-end gap-3 pt-2">
            <button
              type="button"
              onClick={onCancel}
              className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isActivating || !licenseKey}
              className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50"
            >
              {isActivating ? (
                <>
                  <Loader2 className="h-4 w-4 animate-spin" />
                  Activating...
                </>
              ) : (
                'Activate License'
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};