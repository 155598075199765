import React, { useState } from 'react';
import { useVaultStore } from '../../services/vaultStore';
import { VaultItem } from './VaultItem';
import { DeleteConfirmModal } from './DeleteConfirmModal';
import { PasswordModal } from './PasswordModal';

interface VaultListProps {
  searchQuery: string;
}

export function VaultList({ searchQuery }: VaultListProps) {
  const { items, deleteItem, toggleFavorite } = useVaultStore();
  const [itemToDelete, setItemToDelete] = useState<string | null>(null);
  const [editingItem, setEditingItem] = useState<string | null>(null);

  const filteredItems = items.filter(
    (item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      (item.username?.toLowerCase() || '').includes(searchQuery.toLowerCase())
  );

  const handleDelete = async (id: string) => {
    try {
      await deleteItem(id);
    } catch (error) {
      // Error handling is done in the store
    }
  };

  const handleEdit = (id: string) => {
    setEditingItem(id);
  };

  const handleToggleFavorite = async (id: string) => {
    try {
      await toggleFavorite(id);
    } catch (error) {
      // Error handling is done in the store
    }
  };

  if (filteredItems.length === 0) {
    return (
      <div className="text-center py-12">
        <p className="text-slate-500 dark:text-slate-400">
          {searchQuery ? 'No items match your search' : 'No items found'}
        </p>
      </div>
    );
  }

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {filteredItems.map((item) => (
          <VaultItem
            key={item.id}
            item={item}
            onEdit={handleEdit}
            onDelete={() => setItemToDelete(item.id)}
            onToggleFavorite={handleToggleFavorite}
          />
        ))}
      </div>

      <DeleteConfirmModal
        isOpen={!!itemToDelete}
        onClose={() => setItemToDelete(null)}
        onConfirm={() => {
          if (itemToDelete) {
            handleDelete(itemToDelete);
            setItemToDelete(null);
          }
        }}
      />

      {editingItem && (
        <PasswordModal
          isOpen={true}
          onClose={() => setEditingItem(null)}
          editItem={editingItem}
        />
      )}
    </>
  );
}