import { Routes, Route, Navigate } from 'react-router-dom';
import { useEffect } from 'react';
import Layout from './components/Layout';
import Login from './pages/Login';
import Register from './pages/Register';
import Dashboard from './pages/Dashboard';
import Vault from './pages/Vault';
import Settings from './pages/Settings';
import DataBreach from './pages/DataBreach';
import KeyManager from './pages/KeyManager';
import { AuthGuard } from './components/AuthGuard';
import { useThemeStore } from './store/themeStore';

export default function App() {
  const { isDark } = useThemeStore();

  // Apply theme on mount and when isDark changes
  useEffect(() => {
    if (isDark) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [isDark]);

  return (
    <div className="h-full w-full fixed inset-0 overflow-hidden">
      <Routes>
        {/* Public Routes */}
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />

        {/* Protected Routes */}
        <Route element={<AuthGuard><Layout /></AuthGuard>}>
          <Route index element={<Dashboard />} />
          <Route path="vault" element={<Vault />} />
          <Route path="settings" element={<Settings />} />
          <Route path="data-breach" element={<DataBreach />} />
          <Route path="license-keys" element={<KeyManager />} />
        </Route>

        {/* Redirect all unknown routes to home */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </div>
  );
}