import { openDB, IDBPDatabase } from 'idb';
import { generateUUID } from '../utils/uuid';
import toast from 'react-hot-toast';

const DB_NAME = 'dna-vault';
const DB_VERSION = 1;

interface VaultDB extends IDBPDatabase {
  objectStoreNames: string[];
}

class StorageService {
  private db: VaultDB | null = null;

  async init() {
    try {
      this.db = await openDB(DB_NAME, DB_VERSION, {
        upgrade(db) {
          // Delete existing stores if they exist
          if (db.objectStoreNames.contains('vault')) {
            db.deleteObjectStore('vault');
          }

          // Create fresh stores
          const vaultStore = db.createObjectStore('vault', { keyPath: 'id' });
          vaultStore.createIndex('category', 'category');
          vaultStore.createIndex('favorite', 'favorite');
          vaultStore.createIndex('lastModified', 'lastModified');
        },
      });

      return true;
    } catch (error) {
      console.error('Failed to initialize storage:', error);
      toast.error('Failed to initialize secure storage');
      throw error;
    }
  }

  isInitialized(): boolean {
    return this.db !== null;
  }

  async clearAllData(): Promise<void> {
    if (!this.db) return;

    try {
      const tx = this.db.transaction('vault', 'readwrite');
      await tx.objectStore('vault').clear();
      await tx.done;
    } catch (error) {
      console.error('Failed to clear data:', error);
      throw error;
    }
  }

  async saveVaultItem(item: any) {
    if (!this.db) await this.init();
    const tx = this.db!.transaction('vault', 'readwrite');
    await tx.objectStore('vault').put(item);
  }

  async getVaultItem(id: string) {
    if (!this.db) await this.init();
    const tx = this.db!.transaction('vault', 'readonly');
    return await tx.objectStore('vault').get(id);
  }

  async getAllVaultItems() {
    if (!this.db) await this.init();
    const tx = this.db!.transaction('vault', 'readonly');
    return await tx.objectStore('vault').getAll();
  }

  async deleteVaultItem(id: string) {
    if (!this.db) await this.init();
    const tx = this.db!.transaction('vault', 'readwrite');
    await tx.objectStore('vault').delete(id);
  }
}

export const storage = new StorageService();