import { FC } from 'react';
import { Dna } from 'lucide-react';

export const DnaLogo: FC<{ size?: 'sm' | 'md' | 'lg' }> = ({ size = 'md' }) => {
  const sizes = {
    sm: 'h-6 w-6',
    md: 'h-8 w-8',
    lg: 'h-24 w-24'
  };

  return (
    <div className="flex flex-col items-center gap-3">
      <div className={`relative ${size === 'lg' ? 'animate-pulse' : ''}`}>
        <div className="relative">
          <Dna className={`${sizes[size]} text-red-600`} style={{ filter: 'drop-shadow(0 0 10px rgba(255,255,255,0.3))' }} />
          <Dna className={`${sizes[size]} text-white absolute inset-0 opacity-50`} style={{ transform: 'scale(0.95)' }} />
          <Dna className={`${sizes[size]} text-blue-600 absolute inset-0 opacity-50`} style={{ transform: 'scale(0.9)' }} />
        </div>
      </div>
      {size === 'lg' && (
        <span className="font-bold text-4xl text-white tracking-wide">
          DNA Vault
        </span>
      )}
      {size !== 'lg' && (
        <span className={`font-bold ${
          size === 'md' ? 'text-xl' : 'text-lg'
        } text-gray-900 dark:text-white`}>
          DNA Vault
        </span>
      )}
    </div>
  );
};